/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

$ionicons-font-path: "~ionicons/dist/fonts";
// @import "~ionicons/dist/scss/ionicons.scss";

// @font-face {
//     font-family: 'Muli-Regular';
//     src: url('../src/assets/fonts/Muli-Regular.ttf');
// }

@import url('https://fonts.googleapis.com/css?family=Muli:300,400,600,700');

$font-heading: 'Muli-Regular', sans-serif;
$font-body: 'Roboto', sans-serif;

$home-page-font:'poppins';
$home-page-body:'poppins-bold';
@font-face {
    font-family: 'Poppins-Regular';
    src: url('./assets/fonts/Poppins-Regular.ttf');
}
@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('./assets/fonts/Poppins-SemiBold.ttf');
}
@font-face {
    font-family: 'Poppins-Light';
    src: url('./assets/fonts/Poppins-Light.ttf');
}

@font-face {   
    font-family: 'SFProDisplay-Regular'; 
    src: url('../src/assets/fonts/SFProDisplay-Regular.ttf');
}

@font-face {   
    font-family: 'SFProDisplay-Semibold'; 
    src: url('../src/assets/fonts/SFProDisplay-Semibold.ttf');
}

@font-face {   
    font-family: 'SFProDisplay-bold'; 
    src: url('../src/assets/fonts/SFProDisplay-Bold.ttf');
}

@font-face {   
    font-family: 'SFProDisplay-heavy'; 
    src: url('../src/assets/fonts/SFProDisplay-Heavy.ttf');
}

@font-face {   
    font-family: 'SFProDisplay-medium'; 
    src: url('../src/assets/fonts/SFProDisplay-Medium.ttf');
}

ion-title {
    font-size: 22px;
    font-weight: 500;
    font-family: var(--ion-font-head);
    padding: 0px 10px;
}

@function md-type-size($value) {
    @return ($value / 16)+rem;
}

@function md-type-tracking($value, $font-size) {
    @return ($value / ($font-size / 16))+px;
}

// @each $size, $size-value in $screen-breakpoints {
//     .hide-#{$size} {
//       @media (min-width: $size-value) {
//         display: none !important;
//       }
//     }
// }


.md-heading-1 {
    font-family: var(--ion-font-head);
    font-weight: 300;
    font-size: md-type-size(96);
    letter-spacing: md-type-tracking(-1.5, 96);
}

.md-heading-2 {
    font-family: var(--ion-font-head);
    font-weight: 300;
    font-size: md-type-size(60);
    letter-spacing: md-type-tracking(-0.5, 60);
}

.md-heading-3 {
    font-family: var(--ion-font-head);
    font-weight: 400;
    font-size: md-type-size(48);
}

.md-heading-4 {
    font-family: var(--ion-font-head);
    font-weight: 400;
    font-size: md-type-size(34);
    letter-spacing: md-type-tracking(0.25, 34);
}

.md-heading-5 {
    font-family: var(--ion-font-head);
    font-weight: 400;
    font-size: md-type-size(24);
}

.md-heading-6 {
    font-family: var(--ion-font-head);
    font-weight: 600;
    font-size: md-type-size(20);
    letter-spacing: md-type-tracking(0.15, 20);
}

.md-subtitle-1 {
    font-family: var(--ion-font-head);
    font-weight: 400;
    font-size: md-type-size(16);
    letter-spacing: md-type-tracking(0.15, 16);
}

.md-subtitle-2 {
    font-family: var(--ion-font-head);
    font-weight: 600;
    font-size: md-type-size(14);
    letter-spacing: md-type-tracking(0.1, 14);
}

.md-body-1 {
    font-family: $font-body;
    font-weight: 400;
    font-size: md-type-size(18);
    letter-spacing: md-type-tracking(0.5, 16);
}

.md-body-2 {
    font-family: $font-body;
    font-weight: 400;
    font-size: md-type-size(16);
    letter-spacing: md-type-tracking(0.25, 14);
}

.md-button {
    font-family: $font-body;
    font-weight: 600;
    font-size: md-type-size(14);
    letter-spacing: md-type-tracking(0.75, 14);
    text-transform: uppercase;
}

.md-caption {
    font-family: $font-body;
    font-weight: 400;
    font-size: md-type-size(12);
    letter-spacing: md-type-tracking(0.4, 12);
}

.md-overline {
    font-family: $font-body;
    font-weight: 400;
    font-size: md-type-size(10);
    letter-spacing: md-type-tracking(1.5, 10);
    text-transform: uppercase;
}

.login-grid {
    height: 100%;
    align-items:center;
    justify-content:center;
}

.login-c-card {
    margin-top: 120px;
    margin-bottom: 20px;
    padding: 15px;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    border: 1px solid #e5e5e5;
    background-color: #fff;
    border-radius: 15px;
    > *:last-child {
        margin-bottom: 0;
    }
}

.login-logo {
    margin-bottom: 5px;
    margin-top: 50px;
    text-align: center;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    &__img {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        border: 1px solid #e5e5e5;
        position: absolute;
        margin-top: -119px;
        margin-left: -49px;
        background-color: #fff;
        padding: 10px;
    }
    &__text {
        font-size: 20px;
        font-weight: 500;
        font-family: var(--ion-font-head);
        color: #222;
    }
}

.login-paragraph {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    &__text {
        font-size: 14px;
        color: #222;
    }
    &__link {
        display: inline-block;
        font-size: 14px;
        padding-left: 3px;
        text-decoration: underline;
        color: #0059b3;
    }
    &__remember {
        font-size: 14px;
        color: #222;
        text-align: start;
    }
}

.c-form {
    &__label {
        margin-bottom: 5px;
        font-family: $font-body;
        color: #222;
        font-size: 14px;
        margin-top: 9px;
        sup{
            color: danger;
        }
        &-2{
            width: 50%;
        }
        &-3{
            width: 38%;
        }
        &-4{
            width: 100%;
        }
        &-5 {
            width: 30%;
            color: #222;
        }
    }
    &__radio {
        vertical-align: middle;
        margin-right: 4px;
    }
    &__field{
        position: relative;
        width: 100%;
        margin-bottom: 5px;
        padding-right: 5px;
        padding-top: 5px;
    }
    &__input {
        padding: 10px;
        display: block;
        width: 100%;
        border-radius: 4px;
        background-color: #F0F2F5;
        color: #222;
        font-family: $font-body; 
        border: 1px solid #e4e4e4;
        min-height: 40px;
        margin-bottom: 5px;
        text-transform: initial;
    }
    &__select {
        padding: 10px;
        padding-right: 30px;
        display: block;
        width: 100%;
        border-radius: 4px;
        --background: var(--ion-color-primary-bg);
        color: #222;
        font-family: $font-body;
        border: 1px solid #e4e4e4;
        /* appearance: none;
        -webkit-appearance: none; */
        outline: 0!important;
        
    }
    &__date {
        font-size: 14px;
        color: #222;
        font-family: $font-body;
        border: none;
        width: 100%;
        padding: 10px;
        --background: var(--ion-color-primary-bg);
        -webkit-appearance: none;
        appearance: none;
        min-height: 40px;
    }    
}
.c-list {
    margin-bottom: 20px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    // --background: var(--ion-color-primary-bg);
    &__item {
        position: relative;
        padding: 10px;
        width: 100%;
        border-top: 1px solid rgba(0, 0, 0, 0.08);
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        outline: none;
        text-decoration: none;
    }
    &__label {
        font-size: 14px;
        color: #222222;
        font-family: $font-body;
    }
    &__input {
        font-size: 14px;
        color: #919191;
        font-family: $font-body;
        border: none;
        width: 100%;
        padding-left: 5px;
        margin-top: 6px;
        // --background: var(--ion-color-primary-bg);
    }
    &__title {
        font-size: 16px;
        font-weight: 400;
        font-family: $font-heading;
        color: #222;
    }
    &__title-item {
        padding: 10px;
        &--primary {
            background-color: #e6f2ff;
        }
        &--secondary {
            background-color: #D1E2A9;
        }
        &--danger {
            background-color: #fff4f4;
        }
    }
    &__title-icon {
        margin-right: 10px;
        font-size: 18px;
    }
    &__content {
        font-size: 14px;
        color: #919191;
        font-family: $font-body;
    }
    &__input {
        font-size: 14px;
        color: #919191;
        font-family: $font-body;
        border: none;
        width: 100%;
        padding: 0;
        background-color: #fff;
    }
    &__date {
        font-size: 14px;
        color: #919191;
        font-family: $font-body;
        border: none;
        width: 100%;
        padding: 0;
        background-color: #fff;
        -webkit-appearance: none;
        appearance: none;
        height: 16px;
    }
}

.text-primary {
    color: #0059b3;
}
.text-danger {
    color: #f53d3d;
}
.text-secondary {
    color: #00b395;
}
.static {
    position: static;
}

.c-button {
    display: block;
    // --background: var(--ion-color-primary-bg);
    color: #222;
    text-align: center;
    padding: 10px;
    appearance: none;
    -webkit-appearance: none;
    font-size: 14px;
    border-radius: 4px;
    min-height: 40px;
    font-family: $font-body;
    &--fullwidth {
        width: 100%;
    }
    &--primary {
        color: #fff;
        background: #0059b3;
    }
    &--light {
        color: #0059b3;
        background: #e6f2ff;
    }
    &--disabled {
        color: #fff;
        background: #0059b3;
        opacity: 0.5;
        cursor: not-allowed !important;
    }
}

.btn{
    border-radius: 4px;    
    --transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), background-color 15ms linear, color 15ms linear;    
    margin-bottom: 10px;
    height: 36px;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    font-family: var(--ion-font-body);
    letter-spacing: 0.06em;
    text-transform: uppercase;    
    --background-hover: #ffffff;
    --background-focused: #ffffff;
    --background-activated-opacity: 0;
    --background-focused-opacity: 0.24;
    --background-hover-opacity: 0.08;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn-full {
    width: 100%;
}

.error-message {
    color: var(--ion-color-swdanger);
}

::-webkit-scrollbar {
    width:0px;
}

.circleListPopover{
    .popover-content
    {
        width: 600px;
        height: auto;
        overflow: scroll;
        border-radius: 5px;
        margin-left: 7px;
        max-width: 100%;
        right: 0;
        left: -7px
    }    
}

.vchatpopover{
    .popover-content
    {
        width: 400px;
        height: auto;
        overflow: scroll;
        border-radius: 5px;
        margin-left: 7px;
        max-width: 100%;
        right: 0;
        left: -7px
    }    
}

.fileUploadPopover {
    .popover-content
    {
        width: 250px;
        height: 40px;
        /* margin-bottom: 10px; */
        overflow: visible;
        border-radius: 5px;
        right: 10px;
        margin-top: -20px;
    }    
}


.first-time-triage-video {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    position: absolute;
    flex-direction: column;
    /* justify-content: space-between; */
    contain: layout size style;
    overflow: hidden;
    z-index: 999;
}

.video-call-modal-css .modal-wrapper
{
   height: 50%;
   top: 30%;
   position: absolute; 
   display: block; 
}

#js--sc--container{
    max-width: 300px;
    width:100%;
    height: 300px;
    margin: auto;
    position: relative;
    canvas{
        position: absolute;
        top:0px;
        left:0px;
        z-index: 99;
        //border-radius: 5px;
    }
    .cardamountcss{
        z-index: 9;
        top: 50%;
        left: 50%;
        transform: translate(-50% , -50%);
        position: absolute;
        font-weight: bold;

        .won-amnt{
            font-size:5em;
            color: #424242;
        }
        .won-text{
            font-size: 4em;
            color: #424242;
        }
    }
    .sc__inner{
        background-color: #ffffff;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #26005f;
        overflow: hidden;
    }
}

@font-face {
    font-family: 'poppins';    //This is what we are going to call
    src: url('./assets/fonts/Poppins-Regular.ttf');
  }
  
  @font-face {
    font-family: 'poppins-bold';    //This is what we are going to call
    src: url('./assets/fonts/Poppins-Bold.ttf');
  }
  
  @font-face {
    font-family: 'poppins-semibold';    //This is what we are going to call
    src: url('./assets/fonts/Poppins-SemiBold.ttf');
  }
  
  @font-face {
    font-family: 'poppins-light';    //This is what we are going to call
    src: url('./assets/fonts/Poppins-Light.ttf');
  }
  
  @font-face {
    font-family: 'poppins-extralight';    //This is what we are going to call
    src: url('./assets/fonts/Poppins-ExtraLight.ttf');
  }

